exports.components = {
  "component---src-page-templates-instructions-js": () => import("./../../../src/pageTemplates/instructions.js" /* webpackChunkName: "component---src-page-templates-instructions-js" */),
  "component---src-page-templates-product-js": () => import("./../../../src/pageTemplates/product.js" /* webpackChunkName: "component---src-page-templates-product-js" */),
  "component---src-page-templates-products-page-js": () => import("./../../../src/pageTemplates/productsPage.js" /* webpackChunkName: "component---src-page-templates-products-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

